<template>
  <div
    class="uk-padding uk-background-default uk-box-shadow-medium mobile_booking"
  >
    <button class="vm-modal__close" @click="$emit('close')">
      <span uk-icon="icon: close; ratio: 1.5"></span>
    </button>
    <div class="booking_content">
      <h2 class="title">{{ strings.booking_title }}</h2>
      <p class="body_text">{{ strings.booking_text }}</p>
      <hr />

      <div class="booking__options">
        <div class="booking__links">
          <a
            v-for="link in links"
            :key="link.id"
            class="btn_booking"
            :href="link.url"
            :target="'_' + link.target"
            >{{ link.text }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Booking",
  data: function() {
    return {
      strings: this._vaData.strings,
      links: this._vaData.strings.booking_links
    };
  },
  props: {},
  mounted: function() {},
  computed: {},

  methods: {}
};
</script>

<style scoped lang="scss">
.title {
  @extend h2;
}
.mobile_booking {
  @media (max-width: $breakpoint-large) {
    position: absolute !important;
    right: 0;
    top: 0;
    margin-right: calc(100vh - 70vh);
  }
}
.vm--modal > div {
  max-height: 1000px;
  width: 400px;
  padding: 0px;

  @media (max-width: $breakpoint-large) {
    width: 300px;
  }
}
.vm-modal__close {
  right: 20px;
}
.booking_content {
  padding: 40px;
}
.booking__options {
  list-style-type: none;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  .booking__links {
    margin-top: 0;

    a {
      color: $base-body-color;
      width: 100%;
      display: inline-block;
      padding-bottom: 0.5em;
      &:hover {
        color: #dd0060 !important;
      }
    }
  }
}
</style>
<style lang="scss">
.c-va_modal--bookings {
  .linkList {
    a {
      @extend .uk-button;
      @extend .uk-button-primary;
      color: #fff !important;
      width: 100%;
      text-align: left;
      padding-right: 4em;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:after {
        content: "";
        height: 1.5em;
        width: 1.5em;
        background-image: url("../assets/chevron-right.svg");
        position: absolute;
        right: 1em;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
</style>
