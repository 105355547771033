<template>
  <div>
    <div
      id="offcanvas"
      uk-offcanvas="overlay: true; flip:true;mode:reveal;"
      ref="offcanvas"
    >
      <div class="uk-offcanvas-bar container">
        <!-- <div class="uk-margin-medium title">
          <h3
            class="title__main"
            :class="strings.app_subtitle ? 'uk-margin-remove-bottom' : ''"
          >
            {{ strings.app_title }}
          </h3>
          <span lcass="title__sub" v-if="strings.app_subtitle">{{
            strings.app_subtitle
          }}</span>
        </div> -->
        <!-- <EntryBox
          :entries="contents"
          :title="strings.contents"
          :onEntryClick="openContent"
        ></EntryBox> -->
        <LanguageSwitcher />
        <MetaLinks />
        <hr />
        <AppInfo :showTitle="true" />
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
// import EntryBox from "./EntryBox.vue";
import MetaLinks from "./MetaLinks.vue";
import AppInfo from "./AppInfo.vue";

import { bus } from "../main";

import LanguageSwitcher from "./LanguageSwitcher.vue";
export default {
  name: "Offcanvas",
  components: {
    LanguageSwitcher,
    // EntryBox,
    MetaLinks,
    AppInfo
  },
  props: {},
  computed: {},
  data: function() {
    return {
      // https: //stackoverflow.com/a/63283176/1308363
      version: process.env.VUE_APP_VERSION,
      isOpen: false,
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      locations: this._vaData.locations.filter(l => l.show_in_menu),
      contents: this._vaData.contents.filter(l => l.show_in_menu),
      links: this._vaData.strings.contact_links
    };
  },

  mounted() {
    UIkit.util.on("#offcanvas", "show", () => {
      this.isOpen = true;
    });
    UIkit.util.on("#offcanvas", "hide", () => {
      this.isOpen = false;
    });
  },
  methods: {
    getLinkText(href) {
      if (href.indexOf(":") > -1) {
        // works for mailto: and tel: hrefs
        return href.split(":")[1];
      } else {
        return href;
      }
    },
    isPhoneLink: function(href) {
      return href.indexOf("tel:") > -1 ? href.replace("tel:", "") : false;
    },
    openContent(id) {
      UIkit.offcanvas(this.$refs.offcanvas).hide();
      setTimeout(() => {
        bus.$emit("show_content", id);
      }, 500);
    },
    goToLocation(id) {
      UIkit.offcanvas(this.$refs.offcanvas).hide();
      bus.$emit("go_to_location", id);
    }
  }
};
</script>
<style scoped lang="scss">
.container {
  padding-top: 30px;
}
a {
  color: var(--font_color__title);

  &:hover {
    color: var(--font_color__title);
    filter: brightness(110%);
  }
}

hr {
  color: var(--font_color__title);
  opacity: 0.6;
}

h3,
h4,
h5 {
  text-transform: uppercase;
  font-weight: 400;
}

.contact-links {
  a {
    white-space: nowrap;
  }

  i {
    height: 1.2em;
    width: 1.2em;
    display: inline-block;
    margin-right: 0.5em;
    background-size: contain;
    background-position: center;
    margin-bottom: -0.2em;
  }
}

.toggle {
  @extend .uk-padding;
  color: var(--font_color__title);
  z-index: 3 !important;
  cursor: pointer;
  padding-top: 35px;
  padding-right: 30px;
  @media (max-width: 1200px) {
    padding-top: 20px;
  }
  @media (max-width: 960px) {
    padding: 10px;
    padding-right: 40px;
  }
  @media (max-width: 960px) and (max-height: 600px) {
    padding-top: 1px;
  }
}

.uk-offcanvas-bar {
  @extend .uk-padding-small;
  background-color: #fff;
}

.title * {
  text-transform: none;
}

.title__sub {
}
.title__main {
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 1.6em;
  color: var(--font_color__title);
  @media (max-width: $breakpoint-small) {
    margin-top: 10px;
  }
}
.phoneLink {
  color: var(--font_color__title);
}
</style>
