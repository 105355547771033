<template>
  <div class="">
    <button class="vm-modal__close" @click="$emit('close')">
      <span uk-icon="icon: close; ratio: 1.5"></span>
    </button>

    <!-- <div
      class="uk-background-default uk-box-shadow-medium m-va_modal__container"
    >
      <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
    </div> -->
    <div class="main">
      <VueSlickCarousel
        v-bind="slickSettings"
        @beforeChange="beforeChangeCarousel"
        @afterChange="afterChangeCarousel"
        @init="onInitCarousel"
        ref="carousel"
      >
        <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import Slide from "./SlideMedia.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "ModalMedia",
  data: function() {
    return {
      slickSettings: {
        dots: true,
        arrows: true
      },
      slideIndex: 0
    };
  },
  props: {
    title: String,
    subtitle: String,
    slides: Array
  },
  components: {
    Slide,
    VueSlickCarousel
  },
  mounted() {
    this.afterChangeCarousel();
  },
  methods: {
    onInitCarousel() {},
    afterChangeCarousel() {
      const $currentSlide = this.$refs.carousel.$el.querySelector(
        ".slick-current"
      );
      const $video = $currentSlide.querySelector("video");
      if ($video && $video.dataset.autoplay && $video.dataset.videomute) {
        // show controls
        $video.controls = "controls";
        // autoplay
        $video.play();
        // mute video
        $video.muted = true;
      } else if ($video && $video.dataset.autoplay) {
        // show controls
        $video.controls = "controls";
        // autoplay
        $video.play();
      } else if ($video) {
        // show controls
        $video.controls = "controls";
      }
    },
    beforeChangeCarousel() {
      const $currentSlide = this.$refs.carousel.$el.querySelector(
        ".slick-current"
      );
      const $video = $currentSlide.querySelector("video");
      const $iframe = $currentSlide.querySelector("iframe");

      if ($video) {
        $video.pause();
        // hide controls
        $video.controls = "";
      }
      if ($iframe) {
        const src = $iframe.getAttribute("src");
        if (src.includes("youtube")) {
          $iframe.contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            "*"
          );
        }
      }
    }
  },
  created() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.m-va_modal__container {
  overflow: hidden;
}

.vm--modal > div {
  padding-top: 0;
  padding-bottom: 0;
  margin: auto;
}
.vm--modal {
  top: 0% !important;

  &.c-va_modal {
    top: 0% !important;
  }
  &.c-va_modal--media {
    .slick-slider {
      padding-bottom: 40px;
      background: #e6e5e6;
    }
  }
}
.vm-modal__close {
  top: 15px;
  right: 50px;
}
.main {
  max-height: 100%;
  height: 100%;
  // max-height: calc(85vh - 175.5px);
}
</style>
