<template>
  <div>
    <div class="c-va_modal__header">
      <!-- <img class="header_flag" src="../../assets/esylux_flag.svg" /> -->
      <!-- <div class="modal__header">
        <h2 class="header_title" v-if="header_title" v-html="header_title"></h2>
        <h5
          class="header_subtitle"
          v-if="header_subtitle"
          v-html="header_subtitle"
        ></h5>
      </div> -->
      <button
        class="vm-modal__close"
        @click="$emit('close')"
        :title="strings.close"
      >
        <span uk-icon="icon: close; ratio: 2"></span>
      </button>
    </div>
    <div class="c-va_modal__container">
      <div class="aside">
        <div class="aside__header">
          <h2 v-if="title" v-html="title"></h2>
          <h5 v-if="subtitle" v-html="subtitle"></h5>
        </div>

        <vue-custom-scrollbar
          :settings="scrollbar.settings"
          class="aside__body"
        >
          <div v-if="body">
            <vue-markdown>
              {{ body }}
            </vue-markdown>
          </div>
        </vue-custom-scrollbar>

        <div v-if="!isElectron" class="modal_contact_padding">
          <div
            class="uk-grid uk-grid-small uk-child-width-expand uk-margin-remove-top modal_contact"
            uk-grid
          >
            <div v-if="strings.mail_url" class="finder_link__button uk-button">
              <a
                class="finder_link uk-button"
                :href="strings.mail_url"
                target="_blank"
                ><i class="contact_icon" uk-icon="icon: mail; ratio: 1.3"></i
              ></a>
            </div>
            <div v-if="strings.phone" class="finder_link__button uk-button">
              <div class="uk-nav-dropdown">
                <button class="finder_link uk-button">
                  <span uk-icon="icon: receiver; ratio: 1.3" class=""></span>
                </button>
                <vue-custom-scrollbar
                  class="dropdown padding-small"
                  uk-dropdown="pos:top-right; mode:click;'
                  "
                  :settings="scrollbar.settings"
                >
                  <span class="modal_number" :href="strings.phone">{{
                    strings.phone
                  }}</span>
                </vue-custom-scrollbar>
              </div>
            </div>
          </div>
          <div
            class="uk-grid uk-grid-small uk-child-width-expand uk-margin-remove-top"
            uk-grid
          >
            <div v-if="product_link" class="finder_link__button uk-button">
              <a
                class="finder_link uk-button"
                :href="product_link"
                target="_blank"
                ><span uk-icon="icon: world" class="margin-small-right"></span
                >{{ strings.product_link }}</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="main">
        <VueSlickCarousel
          v-bind="slickSettings"
          @beforeChange="beforeChangeCarousel"
          @afterChange="afterChangeCarousel"
          @init="onInitCarousel"
          ref="carousel"
        >
          <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Slide from "./Slide.vue";
// import CallToContact from "./CallToContact.vue";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import isElectron from "is-electron";

// optional style for arrows & dots
export default {
  name: "ModalDefault_desktop",
  data: function () {
    return {
      strings: this._vaData.strings,
      isElectron: isElectron(),
      slickSettings: {
        dots: true,
        arrows: true,
      },
      slideIndex: 0,
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false,
        },
      },
    };
  },
  props: {
    title: String,
    subtitle: String,
    header_title: String,
    header_subtitle: String,
    category: Object,
    body: String,
    slides: Array,
    product_link: String,
    link_list: Array,
  },
  components: {
    VueSlickCarousel,
    Slide,
    vueCustomScrollbar,
    // CallToContact
  },
  created() {},
  mounted() {
    // open all links in modal in new tab
    document.querySelectorAll(".c-va_modal__container a").forEach(($link) => {
      $link.addEventListener("click", (e) => {
        e.preventDefault();
        window.open(e.target.href);
        return;
      });
    });
    document
      .querySelectorAll(".c-va_modal__container video")
      .forEach(($video) => {
        // hide controls of all videos
        $video.controls = "";
      });
    if (document.querySelector(".slick-current video")) {
      document.querySelector(".slick-current video").controls = "controls";
    }

    this.afterChangeCarousel();
  },
  methods: {
    onInitCarousel() {},
    afterChangeCarousel() {
      const $currentSlide =
        this.$refs.carousel.$el.querySelector(".slick-current");
      const $video = $currentSlide.querySelector("video");
      if ($video && $video.dataset.autoplay && $video.dataset.videomute) {
        // show controls
        $video.controls = "controls";
        // autoplay
        $video.play();
        // mute video
        $video.muted = true;
      } else if ($video && $video.dataset.autoplay) {
        // show controls
        $video.controls = "controls";
        // autoplay
        $video.play();
      } else if ($video) {
        // show controls
        $video.controls = "controls";
      }
    },
    beforeChangeCarousel() {
      const $currentSlide =
        this.$refs.carousel.$el.querySelector(".slick-current");
      const $video = $currentSlide.querySelector("video");
      const $iframe = $currentSlide.querySelector("iframe");

      if ($video) {
        $video.pause();
        // hide controls
        $video.controls = "";
      }
      if ($iframe) {
        const src = $iframe.getAttribute("src");
        if (src.includes("youtube")) {
          $iframe.contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            "*"
          );
        }
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.c-va_modal {
  & > div,
  .c-va_modal__container {
    height: 100%;
    overflow: hidden;
  }

  .aside {
    @extend .uk-width-1-3,
      .uk-margin-small-right,
      .uk-box-shadow-medium,
      .uk-flex,
      .uk-flex-column;
    background: #fff;

    height: 95%;
  }

  .aside__header {
    background-color: var(--content_bar__color);
    @extend .uk-padding;
    color: var(--content_color__title);
    font-weight: 400;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    h2 {
      font-weight: 400;
      margin: 0;
      color: var(--content_color__title);
      text-transform: var(--content_title_transform);
      font-size: var(--content_size__title) !important;
    }

    h3 {
      margin: 0;
      color: var(--content_color__title);
      font-size: var(--content_size__slide);
      font-weight: 400;
    }
    h5 {
      margin: 0;
      margin-top: 5px;
      color: var(--content_color__title);
      text-transform: var(--content_title_transform);
      font-size: 15px;
      font-weight: 400;
      line-height: 1.4em;
    }
  }

  .aside__body {
    @extend .uk-flex-1;
    padding: 20px 30px;
    height: 100%;
    max-height: calc(85vh - 93.5px);
    overflow-y: scroll;
    font-size: var(--content_size__body);
    color: var(--content_color__body);
    line-height: 1.5;
    h2 {
      font-size: var(--content_size__slide) !important;
    }
    h3 {
      color: var(--content_color__title);
    }
  }

  .aside__footer {
    position: relative;
    z-index: 1;
    @extend .uk-padding;
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid $global-muted-color;
    background-color: #efefef;
  }

  .main {
    @extend .uk-width-expand, .uk-background-default, .uk-box-shadow-medium;

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      & > div,
      .slide {
        background: #fff;
        height: 100%;
      }
    }

    .slide {
    }
  }
}
.finder_link__button {
  @extend .uk-background-default;
  padding: 0px 0 0 15px !important;
  a {
    color: #fff;
    background-color: var(--button_color__hotspot);
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 0px 0;
    span {
      svg {
        margin-bottom: 3px;
      }
    }
  }
  button {
    color: #fff;
    background-color: var(--button_color__hotspot);
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 0px 0;
  }
  @media (max-width: 1200px) {
    a {
      font-size: 12px;
    }
    button {
      font-size: 12px;
    }
  }
  @media (max-width: 1100px) {
    a {
      font-size: 10px;
    }
    button {
      font-size: 10px;
    }
  }
}
.finder_link {
  @extend .uk-button, .uk-button-primary, .uk-button-large;
  background-color: var(--button_color__hotspot);

  &:hover {
    background-color: var(--button_hover__hotspot);
  }
}
.modal_contact_padding {
  padding: 20px 30px;
}
.modal_contact {
  margin-bottom: 20px !important;
}
.modal_number {
  color: $global-muted-color;
  font-size: 14px !important;
  pointer-events: none;
  padding: 0 !important;
}
.padding-small {
  padding: 15px !important;
}
.margin-small-right {
  margin-right: 10px !important;

  @media (max-width: 1200px) {
    margin-right: 5px !important;
  }
  @media (max-width: 1100px) {
    margin-right: 0px !important;
  }
}

.vm-modal__close {
  width: 25px !important;
  height: 25px !important;
  padding: 0.1em !important;

  @media (max-width: 800px) {
    width: 25px !important;
    height: 25px !important;
    padding: 0.2em !important;
  }
  @media (max-height: 720px) {
    width: 20px !important;
    height: 20px !important;
    padding: 0.1em !important;
  }
}
.contact_icon {
  pointer-events: none;
}
.c-va_modal__header {
  background-color: #fff;
  width: 100%;
  height: calc(5% - 5px);
  height: -webkit-calc(5% - 5px);
  height: -moz-calc(5% - 5px);
  height: -o-calc(5% - 5px);
  margin-bottom: 5px;
  position: relative;
}
</style>
