<template>
  <div class="uk-position-relative slide">
    <div v-if="slide.title || slide.subtitle" class="header">
      <img
        v-if="slide.icon_image"
        :src="_vaData.apiBase + slide.icon_image"
        class="header_icon"
      />
      <!-- <img :src="logging(slide.icon_image)" /> -->
      <h3 class="slide__title" v-if="slide.title">{{ slide.title }}</h3>
      <span class="slide__subtitle" v-if="slide.subtitle">{{
        slide.subtitle
      }}</span>
    </div>
    <vue-custom-scrollbar
      :settings="scrollbar.settings"
      class="slide__body"
      :class="[
        slide.title ? 'slide_body_header' : 'slide_body_no--header',
        slide.type === 'iframe_full' ? 'slide__iframe_full' : null
      ]"
    >
      <div
        class="slide__media"
        :class="slide.type === 'iframe_full' ? 'slide__iframe_full' : null"
      >
        <div v-if="!slide.src" class="media">
          <!-- Fallback -->
          <img src="fallback.jpg" alt="Asset not found" />
        </div>

        <template v-else>
          <div v-if="slide.type === 'video'" class="media">
            <video
              :data-autoplay="slide.video_autoplay"
              :data-videomute="slide.video_mute"
              class="uk-width-1-1"
              controls
              :poster="_vaData.apiBase + slide.poster"
              :src="_vaData.apiBase + slide.src"
            ></video>
          </div>

          <div v-if="slide.type === 'image'" class="media">
            <img :src="_vaData.apiBase + slide.src" :alt="slide.title" />
          </div>

          <div v-if="slide.type === 'pdf'" class="media">
            <div
              class="vx-ratio-box mobile_fullscreen vx-ratio-box-85 uk-background-muted"
            >
              <iframe
                :src="`/presenter-api${slide.src}`"
                frameborder="0"
              ></iframe>
            </div>
          </div>

          <div v-if="slide.type === 'iframe'" class="media">
            <div v-if="!isElectron">
              <div class=" media_iframe">
                <iframe
                  :src="
                    slide.src +
                      (slide.src.includes('youtube') ? '?enablejsapi=1' : '')
                  "
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div v-else class="media">
              <video
                :data-autoplay="slide.video_autoplay"
                :data-videomute="slide.video_mute"
                class="uk-width-1-1"
                controls
                :poster="_vaData.apiBase + slide.poster"
                :src="_vaData.apiBase + slide.video"
              ></video>
            </div>
          </div>

          <div v-if="slide.type === 'iframe_full'" class="media iframe_full">
            <div v-if="!isElectron">
              <div class="vx-ratio-box-0 media_iframe uk-background-muted">
                <iframe
                  :src="
                    slide.src +
                      (slide.src.includes('youtube') ? '?enablejsapi=1' : '')
                  "
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div v-else class="media">
              <video
                :data-autoplay="slide.video_autoplay"
                :data-videomute="slide.video_mute"
                class="uk-width-1-1"
                controls
                :poster="_vaData.apiBase + slide.poster"
                :src="_vaData.apiBase + slide.video"
              ></video>
            </div>
          </div>
        </template>
      </div>
      <div v-if="slide.body && slide.type !== 'iframe_full'" class="body">
        <vue-markdown>{{ slide.body }}</vue-markdown>
      </div>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import isElectron from "is-electron";

export default {
  name: "Slide",
  components: {
    vueCustomScrollbar
  },
  mounted() {
    const isSmall = window.innerWidth < 960;
    this.scrollbar.settings.swicher = !isSmall;
  },
  data() {
    return {
      strings: this._vaData.strings,
      isElectron: isElectron(),
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: true,
          swicher: true
        }
      }
    };
  },
  created() {
    // console.log(this.$isMobile());
  },
  props: {
    slide: Object
  },
  methods: {
    logging(str) {
      console.log(str);
    }
  }
};
console.log(`is Electron: ${isElectron()}`);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  @extend .uk-padding;
  padding-bottom: 25px;
  padding-right: 70px;
  @media (min-width: 960px) {
    height: 60px;
    height: 35px;
  }
}
.slide__title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2;
  color: var(--content_color__title);
  font-size: var(--content_size__slide);
  text-transform: var(--content_title_transform);
  font-weight: 400;
}
.slide__subtitle {
  color: var(--content_color__body);
}

.slide__media {
  position: relative;
}
.slide__qrcode {
  position: relative;
}
.slide__body {
  @extend .uk-flex-1;
  font-size: var(--content_size__body);
  color: var(--content_color__body);

  // max-height: calc(85vh - 175.5px);
  overflow-y: scroll;
  // @media (min-width: $breakpoint-large) {
  //   max-height: calc(85vh - 95.5px);
  // }

  @media (max-width: 960px) {
    max-height: 100%;
  }
}

.slide_body_header {
  max-height: calc(85vh - 93.5px);
}
.slide_body_no--header {
  max-height: calc(85vh - 9.5px);
}
.slide__iframe_full {
  height: 100%;
}
.slide__iframe_full {
  max-height: 100%;
  height: 100%;
}

.media {
  position: relative;
  text-align: center;
  aspect-ratio: 16 / 9;
  display: block;
  width: 100%;
  overflow: hidden;
  &:before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }
  &:after {
    display: block;
    content: "";
    clear: both;
  }
  img {
    height: 100%;
  }
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 0.5em 1em;
  font-size: 11px;
  line-height: 1.5;
  // color: #fff;
  span {
    position: relative;
    z-index: 1;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.5;
  }
}

.media_iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  iframe {
    height: 100%;
    width: 100%;
  }
}
.iframe_full {
  height: 100%;
}
.body {
  padding: 20px;
}
.header_icon {
  float: left;
  margin-top: 3px;
  margin-right: 10px;
  width: 30px;
}
</style>
