<template>
  <div class="footer">
    <div>
      <!-- left -->
      <!-- <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <div
          class="footer__button icon-button icon-button--home"
          @click="goHome"
        ></div>
      </transition> -->
      <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <div
          v-if="showBack"
          class="footer__button icon-button icon-button--back"
          @click="goBack"
        ></div>
      </transition>
      <Navigation class="footer__button" />
    </div>
    <div>
      <!-- center -->
    </div>
    <div>
      <!-- right -->
      <a
        class="footer__button cta__button"
        v-if="strings.cta_url"
        :href="strings.cta_url"
        target="_blank"
      >
        <span class="button__text">{{ strings.cta_text || "Website" }}</span>
      </a>
    </div>
  </div>
</template>
<script>
import { bus } from "../main";
import Navigation from "./Navigation.vue";
export default {
  name: "Footer",
  components: {
    Navigation,
  },
  data() {
    return {
      isHome: false,
      showBack: false,
      strings: this._vaData.strings,
    };
  },
  mounted() {
    bus.$on("location_reached", (id, history) => {
      const location = this._vaData.locations.find((l) => l.id === id);
      this.showBack = location.show_back_btn && history.length > 0;
      // this.isHome = this._vaData.settings.start_location === id;
      this.isHome = location.show_back_btn;
    });

    bus.$on("location_left", () => {
      this.showBack = false;
    });
  },
  props: {},
  computed: {},
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goHome() {
      bus.$emit("go_to_location", this._vaData.settings.start_location);
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
body {
  &.is-mobile {
    .footer {
      display: none;
    }
  }
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
  pointer-events: none;
  // background: rgba(#fff, 0.2);
  z-index: 100;
  padding: 0 45px;

  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
  }
}

.footer__button {
  pointer-events: all;
  // margin-left: 3px;
  text-transform: none;
  font-size: var(--font_size__button);
  background-color: var(--footer_color);
  border-left: 1px solid rgba(#fff, 0.1);
  border-right: 1px solid rgba(#000, 0.1);
  text-align: center;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 100%;

  &:last-child() {
    border-right: 0;
  }
  &:first-child() {
    border-left: 0;
  }

  &:hover {
    background-color: var(--footer_color__hover);
  }

  ::v-deep .trigger {
    padding: 0 20px;
  }
}
.icon-button {
  width: 50px;
}
.icon-button--home {
  background-size: auto 48%;
  background-image: url(../assets/haus.svg);
}
.icon-button--back {
  background-size: auto 72%;
  background-image: url(../assets/arrow_left.svg);
}
.cta__button {
  padding: 20px;
}
a:hover {
  text-decoration: none;
  color: #fff;
}
</style>
